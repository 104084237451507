import { visit } from '@hotwired/turbo'
import { Stimulus, Controller } from '#/layouts/js/stimulus'

Stimulus.register('congregation', class extends Controller {
  async select() {
    await fetch('/congregations', {
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      method: 'PATCH',
      body: new FormData(document.getElementById('congregations'))
    })
    visit(location.href.match(/\/\d+/) ? '/' : location) // Redirect to home page if on specific user/event
  }
})
